<template>
  <div>
    <!-- <v-col cols="12" v-if="this.events.length <= 0">
      <v-card color="py-4">
        <v-card-text>
          <div class="text-center">
            <h2 class="black--text">No tienes eventos asignados ...</h2>
            <img
              class="mt-6"
              src="../../assets/ilustracions/undraw_Events_re_98ue.svg"
              height="200"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-col> -->
    <h1>Mis eventos</h1>
    <v-row class="fill-height">
      <!-- calendario -->
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat color="white">
            <!-- Botón Agregar Evento -->

            <v-btn outlined class="mr-4" @click="setToday"> Hoy </v-btn>
            <v-btn fab text small @click="prev">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small @click="next">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on }">
                <v-btn outlined v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Día</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Semana</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Mes</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 días</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :event-margin-bottom="3"
            :now="today"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          ></v-calendar>

          <!-- Agregar Modal Agregar Evento -->
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "MyCalendar",
  head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function () {
      return {
        inner: "Video llamadas",
      };
    },
  },

  async mounted() {
    this.setOver(true);
    await this.initialize();
    // console.log(new Date().toISOString().substr(0, 10));
    // console.log(moment().format("YYYY-MM-DD HH:mm:ss"))
    this.setOver(false);
    this.$refs.calendar.checkChange();
  },
  data() {
    return {
      today: moment().format("YYYY-MM-DD"),
      focus: moment().format("YYYY-MM-DD"),
      type: "month",
      typeToLabel: {
        month: "Mes",
        week: "Semana",
        day: "Día",
        "4day": "4 Días",
      },
      events: [],
    };
  },
  computed: {
    // ...mapGetters("calendar", ["getErrors", "getEvents"]),

    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },

  methods: {
    ...mapActions("calendar", ["getEventsByUser", "setErrors"]),
    ...mapActions(["setOver"]),

    async initialize() {
      const getEvents = await this.getEventsByUser();
      console.log(getEvents)
      // console.log(getEvents);
      this.events = getEvents.map((e) => {
        return {
          ...e,
          name: e.event_name,
          start: new Date(e.date_start).toISOString().substring(0, 16),
          end: new Date(e.date_end).toISOString().substring(0, 16),
        };
      });

      this.setErrors("clear");
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      // console.log(event);

      this.$router.push({ name: "EventDetail", params: { id: event.id } });
      // this.editedItem = {
      //   ...event,
      //   date_start: new Date(event.date_start).toISOString().substring(0, 16),
      //   date_end: new Date(event.date_end).toISOString().substring(0, 16),
      // };
      // this.dialog = true;

      // nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
      this.start = start;
      this.end = end;
    },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
  },
};
</script>

<style scoped>
input {
  border: 0 !important;
}
</style>
